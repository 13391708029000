<template>
    <div>
      <div style="padding: 5px 0">
        <el-button @click="add" type="primary" size="mini" style="margin: 10px 0">新增</el-button>
      </div>
      <el-table :data="tableData" border stripe style="width: 100%">
        <!-- <el-table-column prop="id" label="ID" width="100"> </el-table-column> -->
        <el-table-column label="序号" width="100" type="index" :index="indexMethod"></el-table-column>
        <el-table-column prop="departmentName" label="系名"> </el-table-column>
        <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>{{scope.row.createTime | formatTime}}</span>
            </template>
        </el-table-column>
  
        <el-table-column
            fixed="right"
            label="操作"
            width="200">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" circle  @click="edit(scope.row)"></el-button>
            <el-popconfirm
                @confirm="del(scope.row.id)"
                title="确定删除？"
            >
              <el-button type="danger" icon="el-icon-delete" circle slot="reference" style="margin-left: 10px"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- 弹窗   -->
      <el-dialog title="系名信息" :visible.sync="dialogFormVisible" width="30%"
                 :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <el-form :model="entity">
          <el-form-item label="系名" label-width="120px">
            <el-input v-model="entity.departmentName" autocomplete="off" style="width: 80%"></el-input>
          </el-form-item>
  
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import API from '@/utils/request'
  import {formatDate,formatDates} from '@/utils/formatDate'
  const url = "/api/department"
  
  export default {
    name: "Department",
    data() {
      return {
        user: {},
        tableData: [],
        entity: {},
        dialogFormVisible: false,
      };
    },
    created() {
      this.user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
      this.load()
    },
    filters:{
        formatTime (val) {
            let data = new Date(val)
            return formatDates(data,'yyyy-MM-dd hh:mm:ss');
        }
    },
    methods: {
      indexMethod (index) {
        return index + 1;
      },
      load() {
         API.get(url + "/findAll").then(res => {
            this.tableData = res.data || []
         }) 
      },
      add() {
        this.entity = {}
        this.dialogFormVisible = true
      },
      edit(obj) {
        this.entity = JSON.parse(JSON.stringify(obj))
        this.dialogFormVisible = true
      },
      save() {
        if (!this.entity.id) {
          API.post(url + '/addDepartment', this.entity).then(res => {
            this.$message({
              type: "success",
              message: "操作成功"
            })
            this.load()
            this.dialogFormVisible = false
          })
        } else {
          API.put(url + '/update', this.entity).then(res => {
            this.$message({
              type: "success",
              message: "操作成功"
            })
            this.load()
            this.dialogFormVisible = false
          })
        }
  
      },
      del(id) {
        API.delete(url +'/delete/' + id).then(res => {
          this.$message({
            type: "success",
            message: "操作成功"
          })
          this.load()
        })
      }
    },
  }
  </script>
  
  <style scoped>
  </style>
  